@import 'src/styles/variables';

.background {
  display: block;
  background-color: $black6;
  height: 8px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 40px;
  border-radius: 999px;
}

.progress {
  height: 8px;
  background-color: $primaryColor;
  width: var(--progress);
  border-radius: 999px;
  -webkit-transition: width 2s ease-in-out;
  -moz-transition: width 2s ease-in-out;
  -o-transition: width 2s ease-in-out;
  transition: width 2s ease-in-out;
}
